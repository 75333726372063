<script lang="ts" setup>
defineProps({
  tooltipText: {
    type: String,
    required: true,
  },
});
const inputFile = ref();
const handleClick = () => {
  inputFile.value.click();
};
const emit = defineEmits(["textExtracted", "loadingPdf"]);
const fileToUpload = ref<File | null>(null);

const { t } = useI18n();
const toast = useToast();
const validate = (file: File) => {
  if (file.type !== "application/pdf") {
    toast.add({
      title: "Error",
      description: t("upload_pdf.errors.format"),
      icon: "i-ph-x-circle",
      color: "red",
    });
    return false;
  }

  return true;
};

const reset = () => {
  fileToUpload.value = null;
  inputFile.value.value = "";
};

const onFileChange = (event: Event) => {
  const target = event.target as HTMLInputElement;
  const file = target.files?.[0];

  if (!file) {
    return;
  }

  if (!validate(file)) {
    reset();
    return;
  }

  fileToUpload.value = file;

  submit();
};

const sanctumClient = useSanctumClient();
const { submit, inProgress, validationErrors } = useSubmit(
  () => {
    emit("loadingPdf", true);
    const formData = new FormData();
    formData.append("file", fileToUpload.value as File);

    return sanctumClient("/texts/pdf/get-text", {
      method: "POST",
      body: formData,
    });
  },
  {
    onSuccess: (response) => {
      if (!response.text) {
        emit("loadingPdf", false);
        toast.add({
          title: t("upload_pdf.errors.no_text"),
          description: t("upload_pdf.errors.no_text.description"),
          icon: "i-ph-x-circle",
          color: "red",
        });
        return;
      }

      emit("textExtracted", response.text);
      toast.add({
        title: t("upload_pdf.success.title"),
        description: t("upload_pdf.success.description"),
        icon: "i-ph-check-circle",
        color: "green",
      });
      reset();
    },
    onError: (error) => {
      if (validationErrors.value.length > 0) {
        toast.add({
          title: "Error",
          description: validationErrors.value[0].message,
          icon: "i-ph-x-circle",
          color: "red",
        });
      } else {
        toast.add({
          title: "Error",
          description: error.data?.message
            ? error.data?.message
            : t("upload_pdf.errors.unexpected"),
          icon: "i-ph-x-circle",
          color: "red",
        });
      }

      emit("loadingPdf", false);
      reset();
    },
  },
);
</script>

<template>
  <div>
    <UTooltip :text="tooltipText" :popper="{ placement: 'top' }">
      <UButton
        icon="i-ph-file-pdf"
        size="sm"
        variant="soft"
        :loading="inProgress"
        :aria-label="tooltipText"
        @click="handleClick"
      />
    </UTooltip>
    <input
      ref="inputFile"
      type="file"
      class="hidden"
      accept="application/pdf"
      @change="onFileChange"
    />
  </div>
</template>

<style></style>
