<script lang="ts" setup>
import { useDebounceFn } from "@vueuse/shared";
import type { FormSubmitEvent } from "#ui/types";
import { z } from "zod";

const user = useSanctumUser<User>();

const props = defineProps({
  language: {
    type: String,
    required: true,
  },
  deleteUrl: {
    type: Boolean,
    default: false,
  },
  variant: {
    type: String,
    default: "none",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  sourceTool: {
    type: String,
    required: false,
    default: "",
  },
});
const emit = defineEmits(["subtitles-created", "show-pro-modal", "loading"]);
const toast = useToast();

const { t } = useI18n();
const url = ref("");
watch(
  () => props.deleteUrl,
  (value) => {
    if (value) url.value = "";
  },
);
const form = ref();
const success = ref(false);
const sanctumClient = useSanctumClient();
const { submit, inProgress, validationErrors } = useSubmit(
  () => {
    emit("loading", true);

    return sanctumClient<Youtube>("/subtitles", {
      method: "POST",
      body: {
        url: url.value,
        language: props.language,
      },
    });
  },
  {
    onSuccess: (response: Youtube) => {
      emit("loading", false);
      if (
        response.status === STATUS_FAILED ||
        response.status === STATUS_NO_TRANSCRIPT
      ) {
        toast.add({
          title: t("youtube.error"),
          description: t("youtube.error"),
          icon: "i-ph-x-circle-fill",
          color: "red",
        });
        return;
      }

      url.value = "";
      emit("subtitles-created", response.transcript_text);
      success.value = true;
      setTimeout(() => {
        success.value = false;
      }, 5000);
    },
    onError: (error: any) => {
      emit("loading", false);
      if (validationErrors.value.length > 0) {
        form.value?.setErrors(validationErrors.value);
      } else {
        toast.add({
          title: "Error",
          description: error.data?.message ?? t("youtube.error"),
          icon: "i-ph-x-circle-fill",
          color: "red",
        });
      }
    },
  },
);

const schema = z.object({
  url: z
    .string()
    .min(0)
    .max(255)
    .refine((url) => {
      if (!url) return true;
      return isValidYoutubeUrl(url);
    }, t("youtube.invalid_url")),
});

type Schema = z.output<typeof schema>;
const handleSubmit = (_event: FormSubmitEvent<Schema>) => {
  if (url.value) {
    if (!user.value?.is_subscribed) {
      emit("show-pro-modal");
    } else {
      submit();
    }
  }
};

const handleInput = useDebounceFn(() => {
  if (isValidYoutubeUrl(url.value)) {
    if (!user.value?.is_subscribed) {
      emit("show-pro-modal");
    } else {
      submit();
    }
  }
}, 500);

function isValidYoutubeUrl(url: string) {
  const regExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = url.match(regExp);
  return match && match[7].length === 11 ? match[7] : false;
}
</script>

<template>
  <div>
    <PopoverPro
      :description="t('youtube_subtitles.popover')"
      :source-tool="sourceTool"
      :reason-purchase="REASON_PURCHASE_YOUTUBE"
    >
      <UForm
        ref="form"
        :state="{ url: url }"
        :schema="schema"
        class="w-full"
        @submit="handleSubmit"
      >
        <UFormGroup
          :ui="{
            error: 'hidden',
          }"
          name="url"
        >
          <UInput
            v-model="url"
            :variant="
              form && form.getErrors('url').length > 0 ? 'outline' : variant
            "
            :ui="{
              base: 'border-b-1',
              rounded:
                variant === 'outline'
                  ? 'rounded-lg'
                  : 'rounded-b-none rounded-t-lg',
            }"
            :disabled="inProgress || disabled"
            size="xl"
            :placeholder="$t('youtube.placeholder')"
            autocomplete="off"
            @input="handleInput"
          >
            <template #leading>
              <UIcon
                name="i-ph-youtube-logo-fill"
                class="text-red-500 text-2xl"
              />
            </template>

            <template #trailing>
              <span
                v-if="!user || !user.is_subscribed"
                class="flex items-center"
              >
                <UIcon name="i-ph-crown-fill" class="text-xl text-primary" />
              </span>
              <span v-show="inProgress" class="flex items-center">
                <UIcon
                  name="i-ph-circle-notch"
                  class="animate-spin text-primary text-xl"
                />
              </span>

              <span
                v-if="success"
                v-motion-fade
                class="w-5 h-5 rounded-full bg-green-500 text-white flex items-center justify-center"
              >
                <UIcon name="i-ph-check" class="text-sm" />
              </span>
            </template>
          </UInput>
        </UFormGroup>
      </UForm>
    </PopoverPro>
  </div>
</template>

<style></style>
