<script lang="ts" setup>
import imageCompression from "browser-image-compression";

defineProps({
  tooltipText: {
    type: String,
    required: true,
  },
  popoverText: {
    type: String,
    required: true,
  },
  sourceTool: {
    type: String,
    required: false,
    default: "",
  },
});
const emit = defineEmits(["textExtracted", "loadingImageText"]);
const inputFile = ref();

const images = ref<Array<File> | null>(null);
const { t } = useI18n();
const handleClick = () => {
  inputFile.value.click();
};
const compressOptions = {
  maxSizeMB: 1,
  maxWidthOrHeight: 1920,
  useWebWorker: true,
};

const user = useSanctumUser<User>();

const validate = (file: File) => {
  if (!ACCEPTED_IMAGE_TYPES.includes(file.type)) {
    toast.add({
      title: "Error",
      description: t("image_to_text.errors.format"),
      icon: "i-ph-x-circle",
      color: "red",
    });
    return false;
  }

  return true;
};

const reset = () => {
  images.value = null;
  inputFile.value.value = "";
};
const toast = useToast();
const onFilesChange = async (event: Event) => {
  const target = event.target as HTMLInputElement;
  const files = target.files;

  if (!files) {
    return;
  }

  // Validate every file
  for (let i = 0; i < files.length; i++) {
    if (!validate(files[i])) {
      reset();
      return;
    }
  }

  try {
    // Compress every file
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const compressedFile = await imageCompression(file, compressOptions);
      images.value = images.value
        ? [...images.value, compressedFile]
        : [compressedFile];
    }

    submit();
  } catch (e) {
    reset();
    toast.add({
      title: "Error",
      description: t("image_to_text.errors.unexpected"),
      icon: "i-ph-x-circle",
      color: "red",
    });
  }
};
const sanctumClient = useSanctumClient();
const { submit, inProgress } = useSubmit(
  () => {
    emit("loadingImageText", true);
    const formData = new FormData();
    for (const image of images.value!) {
      formData.append("images[]", image);
    }
    return sanctumClient("/text-images", {
      method: "POST",
      body: formData,
    });
  },
  {
    onSuccess: (response: TextImage) => {
      if (response.status === STATUS_SUCCESS) {
        emit("textExtracted", response.output_text);
        toast.add({
          title: t("image_to_text.success.title"),
          description: t("image_to_text.success.description"),
          icon: "i-ph-check-circle-fill",
          color: "green",
        });
      }

      if (response.status === STATUS_TEXT_NOT_FOUND) {
        emit("loadingImageText", false);
        toast.add({
          title: t("image_to_text.errors.no_text.title"),
          description: t("image_to_text.errors.no_text.description"),
          icon: "i-ph-x-circle-fill",
          color: "red",
        });
      }
      reset();
    },
    onError: () => {
      emit("loadingImageText", false);
      toast.add({
        title: "Error",
        description: t("image_to_text.errors.unexpected"),
        icon: "i-ph-x-circle",
        color: "red",
      });
      reset();
    },
  },
);
</script>

<template>
  <div>
    <UTooltip :text="tooltipText" :popper="{ placement: 'top' }">
      <PopoverPro
        :description="popoverText"
        :source-tool="sourceTool"
        :reason-purchase="REASON_PURCHASE_TEXT_IMAGE"
      >
        <UButton
          variant="soft"
          icon="i-ph-image"
          size="sm"
          :aria-label="tooltipText"
          :loading="inProgress"
          @click="user?.is_subscribed ? handleClick() : undefined"
        />
      </PopoverPro>
    </UTooltip>
    <input
      ref="inputFile"
      type="file"
      class="hidden"
      :accept="ACCEPTED_IMAGE_TYPES.join(', ')"
      multiple
      @change="onFilesChange"
    />
  </div>
</template>

<style></style>
